<template>
  <div>
    <b-row>
      <b-col md="2">
        <b-button
          v-if="filterModel.status == 0"
          type="submit"
          variant="primary"
          class="mb-2"
          :disabled="!payment_ids.length"
          @click="confirmAction"
        >
          To'lovlarni tasdiqlash
        </b-button>
      </b-col>
      <b-col md="3" class="mb-1">
        <x-date-picker
          v-model="filterModel.from_date"
          :clearable="false"
          :placeholder="$t('Дата начала')"
          @input="getItems"
        />
      </b-col>
      <b-col md="3" class="mb-1">
        <x-date-picker
          v-model="filterModel.to_date"
          :clearable="false"
          :placeholder="$t('Дата окончания')"
          @input="getItems"
        />
      </b-col>
      <b-col md="1" class="mb-1">
        <b-button
          variant="outline-primary"
          class="btn-tour-skip mr-1"
          @click="clearFilter"
        >
          <feather-icon icon="XIcon" size="16" />
        </b-button>
      </b-col>
      <b-col md="2">
        <b-button
          v-can="'contracts-excel-download'"
          variant="success"
          class="btn-tour-skip text-right mb-2"
          :disabled="xlLoading"
          @click="tableToExcel"
        >
          <feather-icon v-if="xlLoading" icon="LoaderIcon" size="16" />
          <feather-icon v-else icon="DownloadIcon" size="16" />
          Юклаб олиш
        </b-button>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab
        v-for="tab in settingTabs"
        :key="tab.id"
        :title="tab.name"
        @click="onChange(tab.id)"
      >
        <b-overlay :show="loading">
          <good-table-selectable
            v-if="items"
            ref="table"
            :items="items.data"
            :columns="columns"
            :total="items.total"
            :page="page"
            :has-show="true"
            :has-delete="true"
            :status="filterModel.status"
            model="payment"
            :filter="filterModel"
            @getItems="getItems"
            @selectedRows="selectedRows"
            @search="searchFilter"
            @delete="openDeletePayment"
            @editt="openEditPayment"
            @onPageChange="(p) => (page = p)"
          />
        </b-overlay>
      </b-tab>
    </b-tabs>
    <!-- Table for Excel -->
    <div v-if="xlContracts && xlContracts.data">
      <good-table-selectable
        v-show="false"
        ref="tablee"
        class="tablee"
        :items="xlContracts.data"
        :columns="columns"
        :total="xlContracts.total"
        :page="page"
        :status="filterModel.status"
        :filter="filterModel"
      />
    </div>
    <!--  -->
    <b-modal
      v-if="paymentInfo"
      v-model="openEdit"
      cancel-variant="outline-secondary"
      :ok-title="$t('Сохранить')"
      no-close-on-backdrop
      :cancel-title="$t('Отмена')"
      centered
      title="Summani tahrirlash"
      @ok.prevent="save"
    >
      <b-form class="auth-login-form mt-2 row">
        <b-form-group :label="$t('Значение')" label-for="amount" class="col-12">
          <cleave
            id="amount"
            v-model="paymentInfo.amount"
            class="form-control"
            :options="cleaveOption"
            placeholder="0"
          />
        </b-form-group>
        <b-form-group
          v-if="openSmsModal"
          :label="$t('Код')"
          class="col-12"
          label-for="code"
        >
          <b-form-input
            id="code"
            v-model="paymentInfo.sms_code"
            v-mask="'####'"
            name="code"
            type="number"
            placeholder="0000"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <ConfirmCode
      v-if="openSmsComponent"
      :visible="openSmsComponent"
      @close="openSmsComponent = false"
      @save="deletePayment"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { showToast } from "@/utils/toast";
import GoodTableSelectable from "@/views/table/vue-good-table/GoodTableContractCashTable.vue";
import Cleave from "vue-cleave-component";
import ConfirmCode from "@/views/order/components/confirm_code.vue";

export default {
  name: "Index",
  components: {
    GoodTableSelectable,
    Cleave,
    ConfirmCode,
  },
  data() {
    return {
      page: 1,
      loading: false,
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: "thousand" },
      filterModel: {
        per_page: 50,
        number: null,
        is_rejected: 0,
        search: null,
        from_date: null,
        to_date: null,
        status: 0,
        relations: "contract.client",
      },
      openEdit: false,
      paymentInfo: null,
      openSmsModal: false,
      items: null,
      xlLoading: false,
      xlContracts: null,
      openSmsComponent: false,
      form: {
        phone: "",
        code: "",
        payment_id: null,
      },
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
      },
      settingTabs: [
        {
          id: 0,
          name: this.$t("Для подтверждения"),
        },
        {
          id: 1,
          name: this.$t("Одобренные"),
        },
        {
          id: 2,
          name: this.$t("Подтверждено директором"),
        },
      ],
      payment_ids: [],
      columnsTable: [],
      columnsArr: [
        {
          label: "#",
          field: "row_number",
        },
        {
          label: this.$t("Фамилия"),
          field: this.lastnameField,
          filterField: "lastname",
          // field: 'contract.client.lastname',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: this.$t("Имя"),
          field: "contract.client.firstname",
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: this.$t("Отчества"),
          field: "contract.client.middlename",
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: this.$t("Телефон"),
          field: "contract.client.phone",
          filterOptions: {
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: this.$t("Кто зарегистрирован"),
          field: "created_user.name",
          filterField: "passport",
          filterOptions: {
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: this.$t("Сумма"),
          field: "amount",
          isAmount: true,
          filterOptions: {
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: "С какого склада",
          field: "warehouse.name_uz",
        },
        {
          label: this.$t("Дата оплаты"),
          isDate: true,
          field: "created_at",
        },
        {
          label: this.$t("Тури"),
          field: "sold_type",
        },
        {
          label: this.$t("Изоҳ"),
          field: "comment",
        },
        {
          label: this.$t("Действия"),
          field: "action",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      settings: "setting/GET_ITEMS",
      payments: "contract/GET_PAYMENT_ITEMS",
    }),
    columns() {
      return this.columnsTable;
    },
    title() {
      return this.settingTabs[this.filterModel.status].name;
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
    "filterModel.per_page": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
  },
  mounted() {
    this.columnsTable = this.columnsArr;

    this.getItems();
  },
  methods: {
    ...mapActions({
      getItemsAction: "contract/paymentList",
      confirmPayment: "contract/confirmPayment",
      paymentUpdate: "contract/paymentUpdate",
      paymentDelete: "contract/paymentDelete",
      destroyAction: "region/destroy",
      sendForExceptionSituations: "resource/sendForExceptionSituations",
      getCitiesAction: "city/index",
      destroyCityAction: "city/destroy",
    }),
    clearFilter() {
      this.filterModel.from_date = null;
      this.filterModel.to_date = null;
      this.getItems();
    },
    openEditPayment(data) {
      this.openEdit = true;
      this.paymentInfo = data;
    },
    openDeletePayment(id) {
      this.sendForExceptionSituations();
      this.form.payment_id = id;
      this.openSmsComponent = true;
    },
    deletePayment(code) {
      this.paymentDelete({ id: this.form.payment_id, code }).then((res) => {
        this.openSmsComponent = false;
        showToast("success", this.$t("Muvaffaqiyatli o`chirildi"));
        this.getItems();
      });
    },
    save() {
      if (this.openSmsModal) {
        if (
          this.paymentInfo &&
          this.paymentInfo.sms_code &&
          this.paymentInfo.amount
        ) {
          this.paymentUpdate({
            code: this.paymentInfo.sms_code,
            amount: this.paymentInfo.amount,
            id: this.paymentInfo.id,
          }).then((res) => {
            this.openEdit = false;
            this.openSmsModal = false;
            showToast("success", this.$t("Успешно сохранено"));
          });
        } else {
          showToast("warning", this.$t("Заполните необходимые поля"));
        }
      } else {
        this.sendForExceptionSituations();
        this.openSmsModal = true;
      }
    },
    onChange(id) {
      this.filterModel.status = id;
      this.getItems();
      const cols = [...this.columnsArr];
      if (id == 1) {
        cols.splice(
          cols.length - 1,
          0,
          {
            label: this.$t("Кто одобрил"),
            field: "confirmed_user1.name",
          },
          {
            label: this.$t("Дата одобрено"),
            isDate: true,
            field: "confirmed_at_1",
          }
        );
      } else if (id == 2) {
        cols.splice(
          cols.length - 1,
          0,
          {
            label: this.$t("Кто одобрил"),
            field: "confirmed_user1.name",
          },
          {
            label: this.$t("Дата одобрено"),
            isDate: true,
            field: "confirmed_at_1",
          },
          {
            label: this.$t("Кто подтвердил"),
            field: "confirmed_user2.name",
          },
          {
            label: this.$t("Подтвержденная дата"),
            isDate: true,
            field: "confirmed_at_2",
          }
        );
      }
      this.columnsTable = cols;
    },
    selectedRows(rows) {
      this.payment_ids = [];
      if (rows && rows.length) {
        rows.forEach((el) => {
          this.payment_ids.push(el.id);
        });
      }
    },
    confirmAction() {
      this.$bvModal
        .msgBoxConfirm(this.$t("Tasdiqlaysizmi?"), { centered: true })
        .then((value) => {
          if (value) {
            this.confirmation();
          }
        });
    },
    confirmation() {
      this.confirmPayment({ payment_ids: this.payment_ids }).then(() => {
        this.getItems();
        this.payment_ids = [];
        showToast("success", this.$t("Успешно сохранено"));
      });
    },
    searchFilter(search) {
      this.filterModel.search = search;
      this.getItems();
    },
    lastnameField(row) {
      if (row.contract && row.contract.client && row.contract.client.lastname) {
        return row.contract.client.lastname;
      }
      if (row.old_contract) {
        return [
          row.old_contract.lastname,
          row.old_contract.firstname,
          row.old_contract.middlename,
        ].join(" ");
      }
      return null;
    },
    phoneField(row) {
      if (row.contract && row.contract.client && row.contract.client.phone) {
        return row.contract.client.phone;
      }
      if (row.old_contract) {
        return row.old_contract.phone;
      }
      return null;
    },
    middlenameField(row) {
      if (
        row.contract &&
        row.contract.client &&
        row.contract.client.middlename
      ) {
        return row.contract.client.middlename;
      }
      if (row.old_contract) {
        return row.old_contract.middlename;
      }
      return null;
    },
    async getItems() {
      this.loading = true;
      await this.getItemsAction({
        ...this.filterModel,
        page: this.page,
        relations:
          "createdUser|confirmedUser1|confirmedUser2|contract.client|warehouse|oldContract",
      })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getXlItems() {
      this.xlLoading = true;
      const filter = {
        ...this.filterModel,
        per_page: 999,
        page: 1,
        relations:
          "createdUser|confirmedUser1|confirmedUser2|contract.client|warehouse|oldContract",
      };
      await this.getItemsAction(filter)
        .then((res) => {
          this.xlContracts = res.data;
        })
        .finally(() => {
          this.xlLoading = false;
        });
    },
    async tableToExcel(table) {
      await this.getXlItems();
      // await this.getItems()
      const tableeee = document.querySelector(
        "div.tablee div.vgt-responsive table"
      );
      const secondRow = tableeee.querySelector("thead tr:nth-child(2)");
      if (secondRow) {
        secondRow.remove();
      }
      const rows = tableeee.querySelectorAll("table tr");
      rows.forEach((row) => {
        const lastCell = row.lastElementChild
        const firstCell = row.firstElementChild
      
        if (firstCell.querySelector('th.vgt-checkbox-col')) {
          firstCell.remove()
        }
        if (lastCell.querySelector('td.vgt-left-align svg')) {
          lastCell.remove()
        }
      });
      setTimeout(() => {
        if (!table.nodeType) table = tableeee;
        const ctx = { worksheet: `${this.title}`, table: table.innerHTML };
        const link = document.createElement("a");
        link.download = `${this.title}.xls`;
        link.href = this.uri + this.base64(this.format(this.template, ctx));
        link.click();
      }, 10);
    },
  },
};
</script>

<style scoped></style>
